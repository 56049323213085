@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900&subset=cyrillic');

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
  overflow-x: hidden;
  background-color: #f9f9f9;
  overflow: inherit;
}

* {
  margin: 0;
  padding: 0;
}

button,
input {
  font-family: 'Source Sans Pro', sans-serif;
}

.main-dashboard.hidden .nav-bar {
  display: none;
}

.main-dashboard.hidden .side-bar {
  display: none;
}

.main-dashboard.hidden .content-area {
  margin-left: 0;
  padding: 2vh;
}

.main-dashboard.hidden .main-section {
  padding-top: 0;
  height: 100vh;
}

.main-dashboard.hidden .scroll-area {
  /* height: 100% !important; */
}

.main-dashboard.hidden .table-container {
  width: 96%;
}

.main-dashboard.hidden .main-dashboard-form {
  height: 94%;
}

.table .table-row .react-contextmenu-wrapper.react-contextmenu-negative {
  background: #ffb3b3;
}

.table .table-row .react-contextmenu-wrapper.react-contextmenu-selected {
  background: #f2f2f2;
}

.rc-time-picker-panel-select {
  overflow-x: hidden;
}
